import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { baseUrl } from 'src/environments/environment';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  currentAccessToken = localStorage.getItem('XERO_ACCESS_TOKEN_KEY');

  constructor(private http: HttpClient, private nav: NavController) {
    // this.refreshToken()
  }

  // Load accessToken on startup
  async loadToken() {
    const token: any = await localStorage.getItem('XERO_ACCESS_TOKEN_KEY');
    if (token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  // Sign in a user and store access and refres token
  createToken(credentials): Observable<any> {
    return this.http.post(`${baseUrl}/generateAccessToken`, credentials).pipe(
      // switchMap((tokens: { accessToken, refreshToken }) => {
      //   this.currentAccessToken = tokens.accessToken;
      //   const storeAccess = localStorage.setItem('XERO_ACCESS_TOKEN_KEY', tokens.accessToken)
      //   const storeRefresh = localStorage.setItem('XERO_REFRESH_TOKEN_KEY', tokens.refreshToken)
      //   return from(Promise.all([storeAccess, storeRefresh]));
      // }),
      tap(_ => {
        this.currentAccessToken = _.accessToken;
        localStorage.setItem('XERO_ACCESS_TOKEN_KEY', _.accessToken)
        localStorage.setItem('XERO_REFRESH_TOKEN_KEY', _.refreshToken)
        this.isAuthenticated.next(true);
      })
    )
  }

  logout() {
    localStorage.removeItem('XERO_ACCESS_TOKEN_KEY')
    localStorage.removeItem('XERO_REFRESH_TOKEN_KEY')
    //(localStorage.getItem('XERO_REFRESH_TOKEN_KEY'))
   // console.log(localStorage.getItem('XERO_ACCESS_TOKEN_KEY'))
    this.isAuthenticated.next(false);
    // this.nav.navigateRoot('login2', { animated: true, animationDirection: 'back' })
  }

  // Load the refresh token from storage
  // then attach it as the header for one specific API call
  refreshToken() {
    const refreshToken: any = from([localStorage.getItem('XERO_REFRESH_TOKEN_KEY')]);

    //console.log(refreshToken)

    return refreshToken.pipe(
      switchMap((token: any) => {
        if (token) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            })
          }

          return this.http.post(`${baseUrl}/refreshUserXeroToken`, {}, httpOptions);
        } else {
          // No stored refresh token
          return of(null);
        }
      })
    );
  }

  // Store a new access token
  storeAccessToken(accessToken: any) {
    this.currentAccessToken = accessToken
    localStorage.setItem('XERO_ACCESS_TOKEN_KEY', this.currentAccessToken)
    return from([localStorage.setItem('XERO_ACCESS_TOKEN_KEY', this.currentAccessToken)] as any);
  }

}
