import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingGuard implements CanLoad {

  constructor(private nav: NavController) {

  }


  canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<boolean> {
    return new Promise((resolve) => {

      if (localStorage.getItem('login') == null || localStorage.getItem('login') == '' || localStorage.getItem('login') == undefined) {
        resolve(true)
      } else {
        resolve(false)

        let authority = localStorage.getItem('authority')

        if(authority){
          let arrayAuthority = JSON.parse(authority)
          // console.log(arrayAuthority)

          if(arrayAuthority.find(g => g['name'] == 'Dashboard' && g['value'] == true) ){
            this.nav.navigateRoot('tabs/tab1')
            return
          }

          if(arrayAuthority.find(g => g['name'] == 'Accounting' && g['value'] == true) ){
            this.nav.navigateRoot('tabs/tab2')
            return
          }

          if(arrayAuthority.find(g => g['name'] == 'Loan' && g['value'] == true) ){
            this.nav.navigateRoot('tabs/loan-calculator')
            return
          }

          
          // if(arrayAuthority.find(g => g['name'] == 'Loan' && g['value'] == true) ){
            this.nav.navigateRoot('tabs/tab4')
            return
          // }

          
        }else {
          this.nav.navigateRoot('tabs/tab1')
        }
      
      }



    })
  }
}
