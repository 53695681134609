import { Component, OnInit } from '@angular/core';
import { Market } from '@ionic-native/market/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { AltomateService } from '../altomate.service';

@Component({
  selector: 'app-open-mobile',
  templateUrl: './open-mobile.page.html',
  styleUrls: ['./open-mobile.page.scss'],
})
export class OpenMobilePage implements OnInit {

  marketid;

  constructor(public modalCtrl: ModalController, private altomateService: AltomateService, private market: Market, private navParams: NavParams) { }

  ngOnInit() {
    this.marketid = this.navParams.get('marketid')
  }

  close() {
    localStorage.setItem('openmobilebrowser', (Date.now() + 86400000).toString())
    this.modalCtrl.dismiss()
  }

  openUrl() {
    this.altomateService.openUrl('https://altomate.page.link/ezHe')
  }

}
